import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@mui/material";

/*....Common loader use to show at center of screen untill the response from api not comes.....*/

interface Props {
  colour?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    position: "absolute",
    justifyContent: "center",
    margin: "auto",
    width: "100%",
    zIndex: 1000000,
  },
  wrapper: {
    position: "relative",
  },
}));

const Loader: React.FC<Props> = ({ colour = "#8e3643" }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress style={{ color: colour }} />
    </div>
  );
}

export default Loader;