import React, { lazy, Suspense } from "react";
import { Router, Route, withRouter } from "react-router-dom";
import { Redirect, Switch } from "react-router";
import { connect } from "react-redux";
import { history } from "./managers/history";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import Loader from "./modules/common/loader";

const RoutesHandler = withRouter(
  lazy(() => import("./modules/common/routesHandler"))
);

const Routes = (props:any) => {
  let loader = props?.user?.loading ? <Loader /> : null;
  return (
    <MuiThemeProvider muiTheme={getMuiTheme()}>
      <Router history={history}>
        {loader}
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={"/:menu"} component={RoutesHandler} />
            <Route exact path={"/"} component={RoutesHandler} />
            <Redirect exact from="*" to="/" />
          </Switch>
        </Suspense>
      </Router>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state:any) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(Routes);
