import featureFlagConfig from '../utility/featureFlagsConfiguration.json';

let featureFlagList: any = {};
const keys: Array<string> = Object.keys(featureFlagConfig.features);
// Check if there is an env var
// Env var will overwrite the config file value
keys.forEach((key: string) => {
  let configuredValue: any = featureFlagConfig.features[key as keyof typeof featureFlagConfig.features]; // initialize value
  const envVar = process.env[`REACT_APP_${key}` as keyof typeof process.env];
  // if there's an env var, use it
  if (envVar) {
    // convert to boolean if it's a boolean
    if (envVar === 'true' || envVar === 'false') {
      featureFlagList[key] = envVar === 'true';
      return;
    }
    // default to the env var
    featureFlagList[key] = process.env[`REACT_APP_${key}` as keyof typeof process.env];
    return;
  } else {
    // if there's no env var, use the config file
    featureFlagList[key] = configuredValue;
    return;
  }
});


let initialState = {
  ...featureFlagList,
};

export default function featureFlags(state = initialState, action: any) {
  return state;
}
