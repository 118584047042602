import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./assets/languages/en.json";
import it from "./assets/languages/it.json";

i18next.use(initReactI18next).init({
	resources: {
		en: {
			translation: en,
		},
		it: {
			translation: it,
		},
	},
	lng: localStorage.getItem("lng") || "en",
});

export default i18next;