import { combineReducers } from 'redux';
import user from './user';
import nav from './nav';
import featureFlags from './featureFlags';

export default combineReducers({
  user,
  featureFlags,
  nav,
});
