import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import store from "./store.ts";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";
import { Auth0Provider } from "@auth0/auth0-react";
import "./i18react";
import "./assets/styles/reset.css"
import "./assets/styles/global.css"
import "./assets/icons/css/fanblock.css";
import "./index.scss";
import { ToastContainer } from "react-toastify";
function WebApp() {
  const loginRedirectLink =
    process.env.NODE_ENV === "development" ? "http://localhost:3000" : process.env.REACT_APP_REDIRECT_TO_LOGIN;
  return (
    <BrowserRouter>
    <ToastContainer />
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        authorizationParams={{ redirect_uri: loginRedirectLink }} // FOR DEPLOYED PERSPECTIVE
      >
        <ApolloProvider client={client}>
          <Provider store={store}>
            <Routes component={Routes} />
          </Provider>
        </ApolloProvider>
      </Auth0Provider>
    </BrowserRouter>
  );
}

ReactDOM.render(<WebApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
