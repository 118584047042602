/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const sessionManager = {
  setDataInCookies,
  getDataFromCookies,
  removeDataFromCookies,
  setDataInCookiesWithMaxAge,
};

function setDataInCookies(data: any, key: any) {
  cookies.set(key, JSON.stringify(data), { path: '/'});
}

function setDataInCookiesWithMaxAge(data: any, key: any, age: number) {
  cookies.set(key, JSON.stringify(data), { path: '/', maxAge: age});
}

function getDataFromCookies(key: any) {
  return cookies.get(key);
}

function removeDataFromCookies(key: any) {
  cookies.remove(key, { path: '/' });
}
