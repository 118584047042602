import { eventConstants, cookiesConstants, navConstants } from '../constants';

let initialState = {
  isMobiMenuOpen: false,
  isAccountMenuOpen: false,
  isClubSwitcherOpen: false,
};
export default function nav(state = initialState, action: any) {
  switch (action.type) {
    case navConstants.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isMobiMenuOpen: !state.isMobiMenuOpen,
      };
    case navConstants.TOGGLE_ACCOUNT_MENU:
      return {
        ...state,
        isAccountMenuOpen: !state.isAccountMenuOpen,
      };
    case navConstants.TOGGLE_CLUB_SWITCHER:
      return {
        ...state,
        isClubSwitcherOpen: !state.isClubSwitcherOpen,
      };
    case navConstants.SET_MOBILE_MENU:
      return {
        ...state,
        isMobiMenuOpen: action.payload,
      };

    default:
      return state;
  }
}
