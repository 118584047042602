import { eventConstants, cookiesConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

let initialState = {
  isLoggedIn: false,
  loading: false,
  image: "",
};
export default function user(state = initialState, action: any) {
  switch (action.type) {
    case eventConstants.SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case eventConstants.HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case eventConstants.GET_IMAGE:
      return {
        ...state,
        image: action.image,
      };
    case eventConstants.LOGIN_SUCCESS:
      sessionManager.setDataInCookies(
        action.data,
        cookiesConstants.PROFILE_DETAILS
      );
      sessionManager.setDataInCookies(true, cookiesConstants.IS_LOGGED_IN);
      sessionManager.setDataInCookies(false, cookiesConstants.VIEWED_LIVE_MATCH_FIRST_HALF);
      sessionManager.setDataInCookies(false, cookiesConstants.VIEWED_LIVE_MATCH_SECOND_HALF);
      sessionManager.setDataInCookies(false, cookiesConstants.ADDED_BLOCK_NAME);
      return {
        ...state,
        profileDetails: action.data,
        image: localStorage.getItem("profileImage")
      };
    default:
      return state;
  }
}
